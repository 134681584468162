import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Seo from "../components/seo"
import Layout from "../components/layout"
import "../styles/bootstrap.scss"
import "animate.css/animate.min.css";

export default function careers() {
    return (
        <Layout>
            <Seo title="Careers" />
            <img className="parallax" src="/collaboration.jpg" alt="Parallax"/>
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <h1>Careers</h1>
            </div>
            <Container fluid className="bg-white" style={{padding: '3% 0 0', boxShadow: 'inset 0 5px 5px #bebebe'}}>
                <Container className="my-0 py-5">
                    <Row className="justify-content-between">
                        <Col lg={5} className="d-flex flex-column justify-content-around py-5">
                            <h5>When it comes to wireless technologies, TRABUS is changing the conversation.</h5>
                            <p>Let’s change the future of cybersecurity together. Join us now.</p>
                            <Link className="link-button" to="https://trabustechnologies.catsone.com/careers/">View Openings</Link>
                        </Col>
                        <Col lg={6}>
                            <img src="/career.jpg" alt="Career" style={{height: '31vh', objectFit: 'contain' }}/>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Layout>
    )
}
